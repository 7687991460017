import ApolloClient from 'apollo-boost'
import fetch from 'node-fetch';

// Get the URI for Apollo backend from .env file or use localhost
const API_URI = `${process.env.API_URL || 'http://localhost:3001/graphql'}`

// Export the Apollo connection
const createClient = () => new ApolloClient({
  uri: API_URI,
  fetch: fetch
});

export default createClient;
